

















































































































import { computed, defineComponent, onMounted } from "@vue/composition-api";
import BulanSSListVM from "../models/bulanSSList";
import useListViewModel from "@/apps/core/modules/useListViewModel";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import router from "@/router";
import NasabahVM, { Nasabah } from "@/apps/nasabah/models/nasabah";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "BulanSahamSimpananDetail",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new BulanSSListVM();
    const composition = useListViewModel(listVM, false);
    const nasabahId = router.currentRoute.params.id;
    composition.filterMap.nasabah = nasabahId;
    const nasabahVM = new NasabahVM();
    const { instance } = useViewModel(nasabahId, nasabahVM);
    const nasabah = instance as Nasabah;
    const simpPokok = computed(() => {
      const ss = nasabah.summarySimpanan??"{}";
      const ssJson = JSON.parse(ss);
      return ssJson.pokok;
    })
    onMounted(() => composition.onPageChange());

    return {
      // Composition
      ...composition,
      nasabah,
      simpPokok,

      // Method
      formatRupiah,
      toUserDateFormat,
    };
  },
});
