









































































































import { defineComponent } from "@vue/composition-api";
import NasabahListVM from "@/apps/nasabah/models/nasabahList";
import useListViewModel from "@/apps/core/modules/useListViewModel";

export default defineComponent({
  name: "BulanSahamList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new NasabahListVM();
    const composition = useListViewModel(listVM);
    return {
      // Composition
      ...composition,
    };
  },
});
