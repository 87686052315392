import ListAPI from "@/apps/core/api/list";
import { Nasabah, NasabahAPI } from "./nasabah";
import ViewListModel from "@/apps/core/models/viewListModel";

class NasabahAPIListAPI extends ListAPI {
  constructor() {
    super(NasabahAPI.modelPath);
  }
}

class NasabahListVM extends ViewListModel {
  constructor() {
    super(new NasabahAPIListAPI(), undefined, false);
  }

  getInstance(json: Record<string, any>): Nasabah {
    const nasabah = new Nasabah();
    nasabah.loadData(json);
    return nasabah;
  }
}

export default NasabahListVM;
