import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";


class BulanSahamSimpanan extends PlainModel {
  tanggal: string | null = null;
  nilaiSw: string | null = null;
  nilaiSs: string | null = null;
  sw: string | null = null;
  ss: string | null = null;
  total: string | null = null;
  saham: string | null = null;
  penarikan: string | null = null;
}

class BulanSahamSimpananAPI extends API {
  static modelPath = "/laporan/saham-simpanan/";

  constructor() {
    super(BulanSahamSimpananAPI.modelPath);
  }
}



export { BulanSahamSimpanan, BulanSahamSimpananAPI };