import ListAPI from "@/apps/core/api/list";
import { BulanSahamSimpanan, BulanSahamSimpananAPI } from "./bulanSahamSimpanan";
import ViewListModel from "@/apps/core/models/viewListModel";

class BulanSSListAPI extends ListAPI {
  constructor() {
    super(BulanSahamSimpananAPI.modelPath);
  }
}

class BulanSSListVM extends ViewListModel {
  constructor() {
    super(new BulanSSListAPI(), undefined, false);
  }

  getInstance(json: Record<string, any>): BulanSahamSimpanan {
    const bulanSs = new BulanSahamSimpanan();
    bulanSs.loadData(json);
    return bulanSs;
  }
}

export { BulanSSListAPI };
export default BulanSSListVM;
