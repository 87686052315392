












































































































import { defineComponent, onMounted } from "@vue/composition-api";
import useListViewModel from "@/apps/core/modules/useListViewModel";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import router from "@/router";
import NasabahVM from "@/apps/nasabah/models/nasabah";
import useViewModel from "@/apps/core/modules/useViewModel";
import CicilanListVM from "@/apps/pinjaman/models/cicilanList";

export default defineComponent({
  name: "BulanSahamPinjamanDetail",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new CicilanListVM(undefined, false);
    const composition = useListViewModel(listVM, false);
    const nasabahId = router.currentRoute.params.id;
    composition.filterMap.nasabah = nasabahId;
    const nasabahVM = new NasabahVM();
    const { instance } = useViewModel(nasabahId, nasabahVM);
    onMounted(() => composition.onPageChange());

    return {
      // Composition
      ...composition,
      nasabah: instance,

      // Method
      formatRupiah,
      toUserDateTimeFormat,
    };
  },
});
